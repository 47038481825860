import React from 'react';
import ContactMainContent from './ContactMainContent';
import MainLayout from '../MainLayout';

function Contact() {
  return (
    <MainLayout>
      <ContactMainContent />
    </MainLayout>
     
    
  );
}

export default Contact;
