import React from 'react';
import styled from 'styled-components';
import { device } from '../assets/sizes/DeviceSize';

export const Title = ({ children }) => {
  return (
    <Container>
      {children}
    </Container>
  );
};

const Container = styled.h2`
  line-height: normal;
  color: #bc9b5d;
  letter-spacing: 1.2px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bolder;

  @media ${device.mobileS} { 
   font-size: 20px;
  }

  @media ${device.tablet} { 
   font-size: 25px;
  } 

  @media ${device.laptop} { 
    font-size: 30px;
  }
`;
