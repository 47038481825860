import React from 'react';
import big_image3 from '../../assets/images/big_image3.webp';
import {
  StyledContactAnchor,StyledContactCenteredImage,StyledContactCenteredImageDiv,StyledContactImage,StyledContactText,StyledContactTextDiv,StyledContactTitle,StyledContactTitleDiv, 
} from './ContactMainContentStyles';
import big_image8 from '../../assets/images/big_image8.webp';
import { useTranslation } from 'react-i18next';


function ContactMainContent() {
  const { t } = useTranslation();
  return (
   
    <div>
      <div>
        <StyledContactImage src={big_image3}/> 
      </div>
      <StyledContactTitleDiv>
        < StyledContactTextDiv>
          <StyledContactTitle>Contact</StyledContactTitle>
          <StyledContactText>{t(`contact_p1`)}</StyledContactText>
          <StyledContactText>{t(`contact_p2`)} <StyledContactText bold>05 49 59 31 07</StyledContactText> {t(`contact_p3`)} <StyledContactText bold>06 23 78 85 61</StyledContactText></StyledContactText>
          <StyledContactText>email <a style={{ color: `#605E5E` }} href="mailto:chateaudelaroche@free.fr" target="_self">chateaudelaroche@free.fr</a></StyledContactText>
        </ StyledContactTextDiv>

        < StyledContactTextDiv>
          <StyledContactTitle>{t(`contact_p4`)}</StyledContactTitle>
          <StyledContactAnchor href="http://www.chateaux-france.fr/chateau-de-la-roche-magne" target="_blank" >chateaux-france.fr</StyledContactAnchor>
          <StyledContactAnchor href="http://www.monumentum.fr/chateau-roche-gencay-pa00105517.html" target="_blank">monumentum.fr</StyledContactAnchor>
          <StyledContactAnchor href="http://www.petitfute.com/v32595-magne-86160/c1173-visites-points-d-interet/c937-monuments/c949-chateau/39855-chateau-de-la-roche.html" target="_blank">petitfute.com</StyledContactAnchor>
          <StyledContactAnchor href="http://www.tourisme-vienne.com/fr/activite/188/chteau-de-la-roche" target="_blank">tourisme-vienne.com</StyledContactAnchor>
        </ StyledContactTextDiv>
      </StyledContactTitleDiv>
      <StyledContactCenteredImageDiv>
        <StyledContactCenteredImage src={big_image8}/>
      </StyledContactCenteredImageDiv>
    </div>
  );
}

export default ContactMainContent;
