import big_image1 from '../../assets/images/big_image1.webp';
import big_image2 from '../../assets/images/big_image2.webp';
import big_image3 from '../../assets/images/big_image3.webp';
import big_image4 from '../../assets/images/big_image4.webp';
import big_image5 from '../../assets/images/big_image5.webp';
import big_image6 from '../../assets/images/big_image6.webp';
import big_image7 from '../../assets/images/big_image7.webp';
import big_image8 from '../../assets/images/big_image8.webp';
import big_image9 from '../../assets/images/big_image9.webp';
import big_image10 from '../../assets/images/big_image10.webp';
import big_image11 from '../../assets/images/big_image11.webp';
import image12 from '../../assets/images/image12.png';
import image13 from '../../assets/images/image13.jpeg';
import image14 from '../../assets/images/image14.jpeg';

export const images = [
  {
    source: big_image1,
    id: 1,
  },
  {
    source: big_image2,
    id: 2,
  },
  {
    source: big_image3,
    id: 3,
  },
  {
    source: big_image4,
    id: 4,
  },
  {
    source: big_image5,
    id: 5,
  },
  {
    source: big_image6,
    id: 6,
  },
  {
    source: big_image7,
    id: 7,
  },
  
  {
    source: big_image8,
    id: 8,
  },
  {
    source: big_image9,
    id: 9,
  },
  {
    source: big_image10,
    id: 10,
  },
  {
    source: big_image11,
    id: 11,
  },  
  {
    source: image12,
    id: 12,
  },  
  {
    source: image13,
    id: 13,
  },  
  {
    source: image14,
    id: 14,
  },  
  
];
