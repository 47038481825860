import React from 'react';
import big_image8 from '../../assets/images/big_image8.webp';
import {
  StyledMainContent,
  StyledMainContentImage,
  StyledMainContentWrapper,
  StyledMainDiv,
  StyledMainImage,
  StyledMainTitleWrapper,
  Container,
} from './MainContentStyles';
import { useTranslation } from 'react-i18next';
import { Title } from '../Title';
import image13 from '../../assets/images/image13.jpeg';


function MainContent() {
  const { t } = useTranslation();
  return (
    <StyledMainDiv>
      <div>
        <StyledMainImage src={image13}/>
      </div>
      <StyledMainTitleWrapper>
        <Title>{t(`maincontent_title`)}</Title>
      </StyledMainTitleWrapper>
      <div >
        <Container>
          <StyledMainContentWrapper>
            <StyledMainContent>{t(`maincontent_p1`)}</StyledMainContent>
            <StyledMainContentImage src={big_image8} />
          </StyledMainContentWrapper>
         
          <StyledMainContent longerWidth="952px">{t(`maincontent_p2`)}</StyledMainContent>
          <StyledMainContent longerWidth="952px">
            <b>The Chapel</b> {t(`maincontent_p3`)}
          </StyledMainContent >
        </Container>
      </div>
     
      <div>
        <StyledMainContent longerWidth="952px">
          {t(`maincontent_p4`)}
        </StyledMainContent>

        <StyledMainContent longerWidth="952px">
          {t(`maincontent_p5`)}
        </StyledMainContent>
        <StyledMainContent longerWidth="952px">
          {t(`maincontent_p6`)}
        </StyledMainContent>
        <StyledMainContent longerWidth="952px">
          {t(`maincontent_p7`)}
        </StyledMainContent>
        <StyledMainContent longerWidth="952px">
          {t(`maincontent_p8`)}
        </StyledMainContent>
        <StyledMainContent longerWidth="952px">
          {t(`maincontent_p9`)}
        </StyledMainContent>
        <StyledMainContent longerWidth="952px">
          {t(`maincontent_p10`)}
        </StyledMainContent>
        <StyledMainContent longerWidth="952px">
          {t(`maincontent_p11`)}
        </StyledMainContent>
        <StyledMainContent longerWidth="952px">
          {t(`maincontent_p12`)}
        </StyledMainContent>
        <StyledMainContent longerWidth="952px">
          {t(`maincontent_p13`)}
        </StyledMainContent>
        <StyledMainContent longerWidth="952px">
          {t(`maincontent_p14`)}
        </StyledMainContent>
        <StyledMainContent longerWidth="952px">
          {t(`maincontent_p15`)}
        </StyledMainContent>
        <StyledMainContent longerWidth="952px">
          {t(`maincontent_p16`)}
        </StyledMainContent>
      </div>
    </StyledMainDiv>
  );
}

export default MainContent;
