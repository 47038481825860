import React from 'react';
import {
  StyledAnchor,
  StyledFirstTitleSpan,
  StyledSecondTitle,
  StyledThirdTitle,
  StyledWrapper,
} from './HeaderStyles';
import { useTranslation } from 'react-i18next';

function Header() {
  const { t } = useTranslation();
  return (
    <StyledWrapper>
      <div>
        <StyledFirstTitleSpan>
          <StyledAnchor href="https://laroche-wakeflow.web.app/">
            Château de La Roche
          </StyledAnchor>
        </StyledFirstTitleSpan>
        <StyledSecondTitle>{t(`header_secondtitle`)}</StyledSecondTitle>
        <StyledThirdTitle>
          ------ Signez la pétition contre les éoliennes à Magné à &nbsp;
          <StyledThirdTitle>
            <StyledAnchor
              target="_blank"
              rel="noopener noreferrer"
              anchorColor="#DE5021"
              anchorDecoration
              href="http://www.eoliennes-magne.com">{`www.eoliennes-magne.com `}</StyledAnchor>
          </StyledThirdTitle>
          ------
        </StyledThirdTitle>
      </div>
    </StyledWrapper>
  );
}

export default Header;
