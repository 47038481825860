import React from 'react';
import MainLayout from '../MainLayout';
import PhotosMainContent from './PhotosMainContent';

function Photos() {
  return (
   
    <MainLayout>
      <PhotosMainContent />
    </MainLayout>
   
  );
}

export default Photos;
