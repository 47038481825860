import React from 'react';
import {
  StyledNavigationLink,
  StyledLinkWrapper,
  StyledNavigationWrapper,
} from './NavigationStyles';
import { useTranslation } from 'react-i18next';

function Navigation() {
  const { t } = useTranslation();
  return (
    <StyledNavigationWrapper>
      <StyledLinkWrapper>
        <StyledNavigationLink to="/">
          {t(`nav_link1`)}
        </StyledNavigationLink>
        <StyledNavigationLink to="/museum-of-the-order-of-malta">
          {t(`nav_link2`)}
        </StyledNavigationLink>
        <StyledNavigationLink to="/photos">{t(`nav_link3`)}</StyledNavigationLink>
        <StyledNavigationLink to="/location-opening-hours">
          {t(`nav_link4`)}
        </StyledNavigationLink>
        <StyledNavigationLink to="/contact">{t(`nav_link5`)}</StyledNavigationLink>
      </StyledLinkWrapper>
    </StyledNavigationWrapper>
  );
}

export default Navigation;

