import styled from 'styled-components';
import { device } from '../../assets/sizes/DeviceSize';


export const StyledMuseumContentImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const StyledMuseumContentImage = styled.img`
    width: 285px;
    height: 275px;
    object-fit: cover;
    object-position: 50% 50%;

    @media ${device.mobileS} { 
     height: 175px;
     width: auto;
  }
  @media ${device.laptop} { 
     height:285px;
     width: auto;
  }


`;

export const StyledMuseumContentWrapper = styled.div`
  @media ${device.laptop} { 
    display: flex;
    gap:40px;
    justify-content: center;
  }
`;

