import React from 'react';
import {
  StyledFooterAnchor,
  StyledFooterAnchorWrapper,
  StyledFooterLogoImage,
  StyledFooterWrapper,
} from './FooterStyles';
import footerLogo from '../../assets/images/footer-logo.webp';

function Footer() {
  return (
    <StyledFooterWrapper>
      <div>
        <StyledFooterLogoImage src={footerLogo} />
      </div>
      <StyledFooterAnchorWrapper>
        <StyledFooterAnchor
          target="_blank"
          href="https://www.routehistorique86.com/">
          Route Historique
        </StyledFooterAnchor>
        <StyledFooterAnchor
          target="_blank"
          href="https://www.routehistorique86.com/">
          des Abbayes et Monuments
        </StyledFooterAnchor>

        <StyledFooterAnchor
          target="_blank"
          href="https://www.routehistorique86.com/">
          du Haut Poitou
        </StyledFooterAnchor>
      </StyledFooterAnchorWrapper>
    </StyledFooterWrapper>
  );
}

export default Footer;
