import styled from 'styled-components';
import { device } from '../../assets/sizes/DeviceSize';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledPhotosImage = styled.img`
  width: 283px;
  height: 192px;
  object-fit: cover;
  margin: 10px 10px;
  cursor: pointer;

  &:hover {
    opacity: 0.4;
  }
`;

export const StyledPhotosWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  @media ${device.mobileS} { 
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }

  @media ${device.tablet} { 
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    padding: 10px;

    img {
      width: 223px;
    }
  } 

  @media ${device.laptop} { 
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;

    img {
      width: 283px;
    }
  }
`;
