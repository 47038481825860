import MainContent from './components/Main/MainContent';
import MainLayout from './components/MainLayout';

 
function App() {
  return (
    <>
      <MainLayout>
        <MainContent />
      </MainLayout>
    </>
  );
}

export default App;

