import React,{ useState } from 'react';
import { StyledPhotosImage,StyledPhotosWrapper,Container } from './PhotosMainContentStyles';
import Carousel,{ Modal,ModalGateway } from 'react-images';
import { images } from './PhotosSource';


function PhotosMainContent() {
  const [openSlide,setOpenSlide] = useState(false);
  const [currentImage,setCurrentImage] = useState(0);

  const handleImageClick = currentImageId => {
    setCurrentImage(currentImageId);
    setOpenSlide(!openSlide);
  };

  const handleImageClose = () => {
    setOpenSlide(!openSlide);
  };

  return (
    <Container>
      <StyledPhotosWrapper>
        {images.slice(0,3).map(image => (
          <StyledPhotosImage key={image.id} onClick={() => handleImageClick(image.id)} src={image.source}/>
        ))} 
      </StyledPhotosWrapper>
      <StyledPhotosWrapper>
        {images.slice(3,6).map(image => (
          <StyledPhotosImage key={image.id} onClick={() => handleImageClick(image.id)} src={image.source}/>
        ))}
      </StyledPhotosWrapper>
      <StyledPhotosWrapper >
        {images.slice(6,9).map(image => (
          <StyledPhotosImage key={image.id} onClick={() => handleImageClick(image.id)} src={image.source}/>
        ))}
      </StyledPhotosWrapper>
      <StyledPhotosWrapper >
        {images.slice(9,12).map(image => (
          <StyledPhotosImage key={image.id} onClick={() => handleImageClick(image.id)} src={image.source}/>
        ))}
      </StyledPhotosWrapper>
      <StyledPhotosWrapper >
        {images.slice(12,15).map(image => (
          <StyledPhotosImage key={image.id} onClick={() => handleImageClick(image.id)} src={image.source}/>
        ))}
      </StyledPhotosWrapper>
      
      <ModalGateway>
        {openSlide ? (
          <Modal onClose={handleImageClose}>
            <Carousel 
              views={images}
              currentIndex={currentImage - 1}/>
          </Modal>
        ) : null}
      </ModalGateway>
    </Container>
  );
}

export default PhotosMainContent;
