import React from 'react';
import big_image7 from '../../assets/images/big_image7.webp';
import {
  StyledLocationBookAnchor,
  StyledLocationBookAnchorDiv,
  StyledLocationCoordinatesDiv,
  StyledLocationCoordinatesText,
  StyledLocationLeftTextWrapper,StyledLocationLeftWrapper,StyledLocationListItem,StyledLocationMap,StyledLocationRightDiv,StyledLocationText,StyledLocationTitle,StyledLocationWrapper, 
} from './LocationMainContentStyles';
import { useTranslation } from 'react-i18next';
import { Title } from '../Title';
import { StyledMainImage } from '../Main/MainContentStyles';

function LocationMainContent() {
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <StyledMainImage src={big_image7}/>
      </div>
      
     
      <StyledLocationWrapper >
        {/* Location */}
        <div>
          {/* text */}
          <StyledLocationLeftWrapper>
            <Title>{t(`location_location`)}</Title>
            <StyledLocationLeftTextWrapper>
              <StyledLocationText>Château de la Roche</StyledLocationText>
              <StyledLocationText>86160 Magné</StyledLocationText>
            </StyledLocationLeftTextWrapper>
          </StyledLocationLeftWrapper>
          {/* map */}
          <div style={{ marginTop: `25px` }}>
            <StyledLocationMap id="gmap_canvas" src="https://maps.google.com/maps?q=Ch%C3%A2teau%20de%20la%20Roche%20%2086160%20Magn%C3%A9&t=&z=15&ie=UTF8&iwloc=&output=embed" scrolling="no" ></StyledLocationMap>
          </div>
        </div>

        {/* Opening Hours */}
        <div>
          <Title>{t(`location_opening`)}</Title>
          {/* first */}
          <StyledLocationRightDiv >
            <StyledLocationText>{t(`location_p1`)}</ StyledLocationText>
           
            <ul>
              <StyledLocationListItem>
                {t(`location_li1`)}
              </StyledLocationListItem>
              <StyledLocationListItem>
                {t(`location_li2`)}
              </StyledLocationListItem>
              {/* <StyledLocationListItem>
                {t(`location_li3`)}
              </StyledLocationListItem> */}
            </ul>
           
            <StyledLocationText>{t(`location_p2`)}</StyledLocationText>
          </StyledLocationRightDiv>

          {/* second */}
          <StyledLocationRightDiv >
            <StyledLocationText>{t(`location_p3`)}</StyledLocationText>
            <ul>
              <StyledLocationListItem>10:00</StyledLocationListItem>
              <StyledLocationListItem>11.00</StyledLocationListItem>
              <StyledLocationListItem>12.00</StyledLocationListItem>
              <StyledLocationListItem>15.00</StyledLocationListItem>
              <StyledLocationListItem>16.00</StyledLocationListItem>
              <StyledLocationListItem>17.00</StyledLocationListItem>
            </ul>
          </StyledLocationRightDiv>

          {/* third */}
          <StyledLocationRightDiv>
            <StyledLocationText>{t(`location_p4`)}</StyledLocationText>
          </StyledLocationRightDiv>
         
          <StyledLocationRightDiv >
            <StyledLocationText>{t(`location_p5`)}</StyledLocationText>
            <ul>
              <StyledLocationListItem>{t(`location_li4`)}</StyledLocationListItem>
              <StyledLocationListItem>{t(`location_li5`)}</StyledLocationListItem>
              <StyledLocationListItem>{t(`location_li6`)}</StyledLocationListItem>
              <StyledLocationListItem>{t(`location_li7`)}</StyledLocationListItem>
            </ul>
          </StyledLocationRightDiv>

          <StyledLocationBookAnchorDiv>
            <StyledLocationBookAnchor href="https://patrivia.net/visit/chateau-de-laroche-musee-ordre-de-malte;tracking=NRizQU6OXg3C6TTws8rUxv2jgOFtaBYn" target="_blank"><span>{t(`location_bookbutton`)}</span></StyledLocationBookAnchor>
          </StyledLocationBookAnchorDiv>
        </div>

        
      </StyledLocationWrapper>
      <StyledLocationCoordinatesDiv>
        <StyledLocationCoordinatesText bold={`500`}>{t(`location_coordinates`)} <StyledLocationCoordinatesText> 46.3663847, 0.3968901</StyledLocationCoordinatesText></StyledLocationCoordinatesText>
      </StyledLocationCoordinatesDiv>
    
    </div>
  );
}

export default LocationMainContent;
