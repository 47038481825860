import React from 'react';
import MainLayout from '../MainLayout';
import MuseumMainContent from './MuseumMainContent';

function Museum() {
  return (
    <MainLayout>
      <MuseumMainContent />
    </MainLayout>
     
   
  );
}

export default Museum;
