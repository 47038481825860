import styled from 'styled-components';
import { device } from '../../assets/sizes/DeviceSize';

export const StyledFooterWrapper = styled.div`
  background-color: #bc9b5d;
  display: flex;
  align-items: center;
  padding: 20px 20px;
`;

export const StyledFooterLogoImage = styled.img`
  width: 144px;
  height: auto;
  object-fit: cover;
  object-position: 50% 50%;
  margin-left: 60px;
  margin-right: 20px;

  @media ${device.mobileS} { 
    width: 70px;
  }

  @media ${device.tablet} { 
    width: 100px;
    margin-right: 20px;
  } 

  @media ${device.laptop} { 
    width: 144px;
    margin-right: 30px;
  }
`;

export const StyledFooterAnchor = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: #60675e;
  font-size: 30px;

  &:hover {
    color: #cccccc;
    transition: 0.3s ease-out;
  }

  @media ${device.mobileS} { 
   font-size: 15px;
  }

  @media ${device.tablet} { 
    font-size: 20px;
  } 

  @media ${device.laptop} { 
    font-size: 30px;
  }
  
`;

export const StyledFooterAnchorWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
