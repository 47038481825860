import React from 'react';
import styled from 'styled-components';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import LanguageSelect from './Language/LanguageSelect';
import Navigation from './Navigation/Navigation';

const MainLayout = ({ children }) => {
  return (
    <Container>
   
      <div>
        <LanguageSelect />
        <Header />
        <Navigation />
        <Main>
          {children}
        </Main>
    
      </div>
    
      <div>
        <Footer/>
      </div>
    </Container>
  );
};

export default MainLayout;

const Container = styled.div`
  min-height:100vh;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
`;
const Main = styled.div`

`;
