import styled from 'styled-components';
import { device } from '../../assets/sizes/DeviceSize';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  text-align: center;
`;

export const StyledFirstTitleSpan = styled.span`
  letter-spacing: 0.2em;
  font-weight: 300;
  text-align: center;
  
  @media ${device.mobileS} { 
  font-size: 25px;
  }

  @media ${device.tablet} { 
  font-size: 35px;
  }

  @media ${device.laptop} { 
   font-size: 50px;
  }
`;

export const StyledAnchor = styled.a`
  color: inherit;
  text-decoration: ${props => props.anchorDecoration || `none`};
  cursor: pointer;
  color: ${props => props.anchorColor || `inherit`};
`;

export const StyledSecondTitle = styled.p`
  text-align: center;
  font-weight: 200;
  margin-top: 10px;
  margin-bottom: 6px;

   
  @media ${device.mobileS} { 
  font-size: 15px;
  }

  @media ${device.tablet} { 
  font-size: 20px;
  }

  @media ${device.laptop} { 
   font-size: 30px;
  }
`;

export const StyledThirdTitle = styled.span`
  color: #DE5021;
  margin-top: 10px;

   
  @media ${device.mobileS} { 
  font-size: 10px;
  }

  @media ${device.tablet} { 
   font-size: 15px;
  } 


  @media ${device.laptop} { 
   font-size: 20px;
  }
`;
