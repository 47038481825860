import React from 'react';
import UK_flag from '../../assets/images/UK_flag.png';
import France_flag from '../../assets/images/France_flag.png';
import {
  StyledLanguageDiv,
  StyledLanguageName,
  StyledLanguageWrapper,
} from './LanguageSelectStyles';
import i18next from 'i18next';

function LanguageSelect() {
  const handleClick = event => {
    i18next.changeLanguage(
      event.target.innerText.toLowerCase().substring(0,2),
    );
  };

  return (
    <StyledLanguageWrapper>
      <StyledLanguageDiv onClick={handleClick} border>
        <img src={France_flag} />
        <StyledLanguageName border>Français</StyledLanguageName>
      </StyledLanguageDiv>
      <StyledLanguageDiv onClick={handleClick}>
        <img src={UK_flag} />
        <StyledLanguageName>English</StyledLanguageName>
      </StyledLanguageDiv>
    </StyledLanguageWrapper>
  );
}

export default LanguageSelect;
