import React from 'react';
import { StyledMuseumContentImage,StyledMuseumContentImageWrapper,StyledMuseumContentWrapper,StyledParallaxDiv } from './MuseumMainContentStyles';
import big_image11 from '../../assets/images/big_image11.webp';
import { StyledMainContent,StyledMainImage,StyledMainTitleWrapper } from '../Main/MainContentStyles';
import { useTranslation } from 'react-i18next';
import { Title } from '../Title';
import big_image10 from '../../assets/images/big_image10.webp';

function MuseumMainContent() {
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <StyledMainImage src={big_image10}/>
      </div>
      <StyledMuseumContentWrapper>
        <StyledMuseumContentImageWrapper>
          <StyledMuseumContentImage src={big_image11} />
        </StyledMuseumContentImageWrapper>
       
        <div style={{ display: `flex`,flexDirection: `column` }}>
          <StyledMainTitleWrapper style={{ paddingTop: `25px` }}>
            <Title>{t(`museumcontent_title`)}</Title>
          </StyledMainTitleWrapper>
          <StyledMainContent>{t(`museumcontent_p1`)}</StyledMainContent>
          <StyledMainContent>{t(`museumcontent_p2`)}</StyledMainContent>
          <StyledMainContent>{t(`museumcontent_p3`)}</StyledMainContent>
          <StyledMainContent>{t(`museumcontent_p4`)}</StyledMainContent>
          <StyledMainContent>{t(`museumcontent_p5`)}</StyledMainContent>
        </div>
       
      </StyledMuseumContentWrapper>
    </div>
  );
}

export default MuseumMainContent;
