import styled from 'styled-components';
import { device } from '../../assets/sizes/DeviceSize';

export const StyledContactImage = styled.img`
    width: 100%;
    height: 402px;
    object-fit: cover;
    object-position: 50% 50%;
    margin-top: 15px;

    @media ${device.mobileS} { 
      height: 150px;

  }`;

export const StyledContactTitle = styled.span`
  font-style: italic;
  font-weight: 450;
  font-size: 30px;
  line-height: normal;
  color: #bc9b5d;
  letter-spacing: 1.2px;
  padding: 0px 0px 12px calc((100% - 980px) * 0.5);
`;

export const StyledContactText = styled.span`
letter-spacing: 0.04em;
font-size: 15px;
color: #605E5E;
margin-bottom: 20px;
font-weight: ${props => (props.bold ? `600` : `200`)};
`;

export const StyledContactTitleDiv = styled.div`

flex-direction: row;
margin-top: 30px;
justify-content: space-around;
padding: 20px;

@media ${device.laptop} { 
  display: flex;

  }
`;

export const StyledContactTextDiv = styled.div`
display: flex;
flex-direction: column;
`;

export const StyledContactCenteredImageDiv = styled.div`
display: flex;
justify-content: center;
`;
export const StyledContactCenteredImage = styled.img`
width: 293px;
    height: 200px;
    object-fit: cover;
    object-position: 50% 50%;

`;

export const StyledContactAnchor = styled.a`
text-decoration: none;
color:  #bc9b5d;
margin-bottom: 10px;
font-size: 14px;
`;
