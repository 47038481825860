import React from 'react';
import LocationMainContent from './LocationMainContent';
import MainLayout from '../MainLayout';

function Location() {
  return (
    <MainLayout>
      <LocationMainContent />
    </MainLayout>
  );
}

export default Location;
