import styled from 'styled-components';

export const StyledLanguageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  margin-right: calc(10% + 20px);
`;

export const StyledLanguageName = styled.span`
  color: #3f341f;
  cursor: pointer;
  font-size: 14px;
  margin-left: 7px;
  margin-right: ${props => (props.border ? `10px` : `none`)};
  &:hover {
    color: #bc9b5d;
  }
`;

export const StyledLanguageDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 12px;
  border-right: ${props => (props.border ? `1px solid black` : `none`)};
`;
