import App from './App';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Museum from './components/Museum/Museum';
import Photos from './components/Photos/Photos';
import Contact from './components/Contact/Contact';
import Location from './components/Location/Location';


export const Nav = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
        </Routes>
        <Routes>
          <Route path="/museum-of-the-order-of-malta" element={<Museum />} />
        </Routes>
        <Routes>
          <Route path="/photos" element={<Photos />} />
        </Routes>
        <Routes>
          <Route path="/location-opening-hours" element={<Location />} />
        </Routes>
        <Routes>
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

