import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { device } from '../../assets/sizes/DeviceSize';

export const StyledNavigationWrapper = styled.div`
  border-top: 0.4px solid #cccccc;
  margin-top: 8px;
`;

export const StyledLinkWrapper = styled.div`
  display: flex;
  gap:20px;
  justify-content: space-around;
  padding: 15px 35px 0px 35px;
  align-items: center;
  @media (max-width: 600px) { 
    flex-direction:column
  } 

`;

export const StyledNavigationLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: #2f2e2e;
  font-weight: 200;

  &:hover {
    color: #8a8989;
    transition: 0.3s ease-out;
  }

  @media ${device.mobileS} { 
   font-size: 12px;
  }

  @media ${device.tablet} { 
   font-size: 15px;
  } 

  @media ${device.laptop} { 
    font-size: 20px;
  }
`;
