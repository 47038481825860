import styled from 'styled-components';
import { device } from '../../assets/sizes/DeviceSize';


export const StyledLocationTitle = styled.span`
  font-style: italic;
  font-weight: 450;
  font-size: 30px;
  line-height: normal;
  color: #bc9b5d;
  letter-spacing: 1.2px;
  margin-right: 20px;
 
`;

export const StyledLocationText = styled.span`
  color: #605E5E;
  font-size: 14px;
  letter-spacing: 0.04em;
  font-weight: 200;
  max-width: 420px;
`;

export const StyledLocationWrapper = styled.div`
  padding: 20px;
  
  @media ${device.laptop} { 
    display: flex;
    gap:40px;
    justify-content: space-evenly;
    padding: 70px;
  }
`;

export const StyledLocationMap = styled.iframe`
  border-width: inherit;
  height:300px;
  margin-bottom: 20px;
    
  @media ${device.mobileS} { 
     width: 300px;
    }

  @media ${device.tablet} { 
    width: 400px;
  } 

  @media ${device.laptop} { 
    width: 450px;
  }
`;

export const StyledLocationLeftTextWrapper = styled.div`
  display: flex;
  flex-direction: column; 
`;

export const StyledLocationListItem = styled.li`
  color: #605E5E;
  font-size: 14px;
  letter-spacing: 0.04em;
  font-weight: 200;
`;

export const StyledLocationRightDiv = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const StyledLocationBookAnchorDiv = styled.div`
  background-color: #BC9b5d;
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
  width: auto;
  max-width: 300px;

    &:hover {
    background-color: #3F341F;
    transition: 0.5s ease-out;
}`;

export const StyledLocationBookAnchor = styled.a`
  text-decoration: none;
  color: white;
`;

export const StyledLocationCoordinatesDiv = styled.div`
  margin-bottom: 30px;
  margin-left: 30px;
`;

export const StyledLocationCoordinatesText = styled.span`
  color: #605E5E;
  font-size: 15px;
  font-weight: ${props => props.bold || `200`};
`;

export const StyledLocationLeftWrapper = styled.div`
  display: flex;
  gap:40px;
`;
