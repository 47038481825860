import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18nextInit';
import { Nav } from './Nav';

ReactDOM.render(
  <Nav />,
  document.getElementById(`root`),
);
