import styled from 'styled-components';
import { device } from '../../assets/sizes/DeviceSize';

export const StyledMainImage = styled.img`
    width: 100%;
    max-height: 402px;
    object-fit: cover;
    object-position: 50% 50%;
    margin-top: 15px;

    @media ${device.mobileS} { 
      max-height: 350px;

  }

  @media ${device.desktopL} { 
      max-height: 450px;

  }
  `;

export const StyledMainDiv = styled.div`
  overflow-x: hidden;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  //align-items: center;


  @media ${device.laptop} { 
    padding: 10px 0px 12px calc((100% - 980px) * 0.5);
    max-width: fit-content;
  }
`;

export const StyledMainTitleWrapper = styled.div`

@media ${device.mobileS} { 
   font-size: 14px;
   padding: 10px 20px 20px 20px;
  }

  @media ${device.tablet} { 
   font-size: 16px;
   padding: 10px 20px 20px 30px;
  } 

  @media ${device.laptop} { 
    font-size: 20px;
    padding: 10px 0px 12px calc((100% - 980px) * 0.5);
  }
`;

export const StyledMainContentWrapper = styled.div`
  @media ${device.mobileS} { 
  }

  @media ${device.tablet} { 
   display: flex;
   align-items: center;
  } 

  @media ${device.laptop} { 
   display: flex;
   align-items: center;
  }
`;

export const StyledMainContent = styled.p`
  max-width: ${props => props.longerWidth || `529px`};
  word-wrap: break-word;
  font-size: 16px;
  margin-top: 12px;
  color: #605e5e;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;

  @media ${device.mobileS} { 
   font-size: 14px;
   padding: 0px 20px 20px 20px;
  }

  @media ${device.tablet} { 
   font-size: 16px;
   padding: 20px 20px 20px 30px;
  } 

  @media ${device.laptop} { 
    font-size: 20px;
    padding: 0px 0px 12px calc((100% - 980px) * 0.5);
  }
  
`;

export const StyledMainContentImage = styled.img`
 
  @media ${device.mobileS} { 
    margin: auto;
  }

  @media ${device.tablet} { 
    width: 30%;;
  } 

  @media ${device.laptop} { 
    width: 40%;
  }
  height: 281px;
  object-fit: cover;
  object-position: 50% 50%;
  max-width:100vw;
`;

